import React from 'react'
import styled from 'styled-components'
import tw, { theme } from 'twin.macro'
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg'
import Select from 'react-select'
import axios from 'axios'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ReactPixel from 'react-facebook-pixel'

const bookingTimes = [
  { value: '9-11', label: '9:00 - 11: 00' },
  { value: '11-1', label: '11:00 - 13:00' },
  { value: '1-3', label: '13:00 - 15:00' },
  { value: '3-5', label: '15:00 - 17:00' },
]

const interestOptions = [
  { value: 'Ultimate Shred Competition', label: 'Ultimate Shred Competition' },
  { value: 'Ultimate Shred Classes', label: 'Ultimate Shred Classes' },
  { value: 'Ultimate Shred Personal Training ', label: 'Ultimate Shred Personal Training' },
  { value: 'Yorkshire Gladiators Classes', label: 'Yorkshire Gladiators Classes ' },
  {
    value: 'Yorkshire Gladiators Personal Training',
    label: 'Yorkshire Gladiators Personal Training',
  },
  { value: 'Sport Massage Therapy ', label: 'Sport Massage Therapy' },
  { value: 'Ultimate Ink Tattoo ', label: 'Ultimate Ink Tattoo' },
]

const Container = tw.div`relative`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,
  textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-black focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-300`}
    }
  }
`

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`
const Column = tw.div`sm:w-5/12 flex flex-col`
const InputContainer = tw.div`relative py-5 mt-6`
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`
const Input = tw.input``
const SelectInput = tw(Select)`mt-2 text-secondary-900`
// const TextArea = tw.textarea`h-24 sm:h-full resize-none`
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default () => {
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    time: '',
    interests: '',
  })

  const [loading, setLoading] = React.useState(false)

  const handlePost = (event) => {
    event.preventDefault()
    setLoading(true)
    axios({
      method: 'post',
      url: 'https://api.ultimateshred.co.uk/mailerlite',
      data: {
        email: formData.email,
        fields: { time: formData.time, interests: formData.interests },
        resubscribe: false,
        autoresponders: true,
        type: 'active',
        group_name: 'Open Day May 2022',
        name: formData.name,
      },
    })
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          toast.success(`🏋️‍♀️  Thanks ${formData.name} we've got your details!`, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          ReactPixel.track('Open_Day_Form_Submitted', {email: response.data.email})
          setFormData({
            name: '',
            email: '',
            time: '',
            interests: '',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error(
          !formData.name || !formData.email || !formData.time || !formData.interests
            ? `Looks like you've not complete the form, please complete all fields`
            : `Something went wrong and we didn't successfully submit the form, please try again`,
          {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )

        //add error message
        console.log('error', error)
      })
  }

  return (
    <section id="book">
      <Container>
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>Book a time to visit our team</h2>
              <form onSubmit={handlePost}>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">*Your Name</Label>
                      {loading ? (
                        <Skeleton
                          highlightColor={theme`colors.gray.300`}
                          style={{ height: '37px' }}
                        />
                      ) : (
                        <Input
                          required
                          value={formData.name}
                          onChange={(event) =>
                            setFormData((prev) => ({ ...prev, name: event.target.value }))
                          }
                          id="name-input"
                          type="text"
                          name="name"
                          placeholder="E.g. John Doe"
                        />
                      )}
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">*Your Email Address</Label>
                      {loading ? (
                        <Skeleton
                          highlightColor={theme`colors.gray.300`}
                          style={{ height: '37px' }}
                        />
                      ) : (
                        <Input
                          value={formData.email}
                          required
                          className="placeholder-gray-100"
                          id="email-input"
                          type="email"
                          name="email"
                          placeholder="E.g. john@mail.com"
                          onChange={(event) =>
                            setFormData((prev) => ({ ...prev, email: event.target.value }))
                          }
                        />
                      )}
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="booking-input">*Choose a time</Label>
                      {loading ? (
                        <Skeleton
                          highlightColor={theme`colors.gray.300`}
                          style={{ height: '37px' }}
                        />
                      ) : (
                        <SelectInput
                          value={formData.time}
                          required
                          placeholder="Select time slot..."
                          options={bookingTimes}
                          onChange={(event) => setFormData((prev) => ({ ...prev, time: event }))}
                        />
                      )}
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="reason-input">*What's your interests?</Label>
                      {loading ? (
                        <Skeleton
                          highlightColor={theme`colors.gray.300`}
                          style={{ height: '37px' }}
                        />
                      ) : (
                        <SelectInput
                          value={formData.interests}
                          required
                          isMulti
                          options={interestOptions}
                          placeholder="Select interests..."
                          onChange={(event) =>
                            setFormData((prev) => ({ ...prev, interests: event }))
                          }
                        />
                      )}
                    </InputContainer>
                  </Column>
                </TwoColumn>
                <SubmitButton type="submit">Submit</SubmitButton>
              </form>
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
      </Container>
    </section>
  )
}
