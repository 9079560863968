import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as InstagramIcon } from '../../images/instagram.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg'

const Container = tw.div`relative bg-secondary-900 -mx-8 -mb-8 px-8`
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`

const Column = tw.div`md:w-1/5`
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-48`

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 text-white`

const SocialLinksContainer = tw.div`mt-4 `
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src="https://www.ultimateshred.co.uk/wp-content/uploads/2018/08/New-Ultimate-Shred-Logo-Transparent-BG.png" />
          </LogoContainer>
          <CompanyDescription>
            Taking the fitness industry by Storm. We are a team of dedicated fitness professionals
            with a passion for helping people achieve their fitness goals. We strive to make you
            feel confident and content about your health and fitness.
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/Ultimateshredltd">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/ultimate_shred_official">
              <InstagramIcon />
            </SocialLink>
            <SocialLink href="https://www.youtube.com/channel/UC6w0qxiKbSwIbyBO2L6gnAg">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
      </FiveColumns>
    </Container>
  )
}
