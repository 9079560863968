import React from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

import Header from '../headers/light.js'
import AnchorLink from 'react-anchor-link-smooth-scroll'


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full -mb-32`}
`

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/open-day-bg.jpg");
`

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`

export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader />
        <Content>
          <Heading>
            Ultimate Shred & Yorkshire Gladiators
            <br />
            Open Day Event
            <br />
            22<sup>nd</sup> May 22
          </Heading>
         
          <AnchorLink href="#book">
            <PrimaryAction>Book a Slot</PrimaryAction>
          </AnchorLink>
        </Content>
      </HeroContainer>
    </Container>
  )
}
