import React from "react";
import tw from "twin.macro";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;


export default ({ links, className, }) => {
   return (
    <Header className={className || "header-light"}>
      <img src="https://www.ultimateshred.co.uk/wp-content/uploads/2018/08/New-Ultimate-Shred-Logo-Transparent-BG.png" alt="logo" width="150"/>
        {links}
      <img src="https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/Yorkshire-Gladiators.png" alt="logo" width="200"/> 
    </Header>
  );
};


