import ResponsiveVideoEmbed from 'helpers/ResponsiveVideoEmbed'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ReactComponent as SvgDotPatternIcon } from '../../images/dot-pattern.svg'
import { HeadingTitle } from '../misc/Headings.js'

const Container = tw.div`relative`

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const HeadingInfoContainer = tw.div`flex flex-col items-center`
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`

const Content = tw.div`mt-16`

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : 'flex-row',
])
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-contain bg-no-repeat bg-left mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
])
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`
const Title = tw.h4`text-3xl font-bold text-secondary-900`
const Description = tw.p`mt-2 text-sm leading-loose`
const Link = tw.span`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`
const Video = tw.div`text-sm cursor-pointer transition duration-300 border-b-2 lg:w-144 sm:w-80 m-auto items-center justify-center mt-6 `

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`

export default () => {
  const cards = [
    {
      imageSrc:
        'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/277547968_534164794802840_4185352446069418047_n.jpg',
      subtitle: 'Ultimate Shred',
      title: 'Ultimate Male',
      description:
        'Ultimate Male was part of our flagship product originally this was Ultimate Shed which was suitable for both men and women, after receiving feedback we split the plan to focus on male only so you can be sure to get the best results possible.',
      buttonText: 'Find out more',
    },

    {
      imageSrc:
        'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/277455938_2774768932819017_5020476913362618596_n.jpg',
      subtitle: 'Ultimate Shred',
      title: 'Ultimate Female',
      description:
        'Ultimate Female was part of our flagship product originally this was Ultimate Shed which was suitable for both men and women, after receiving feedback we split the plan to focus on female only so you can be sure to get the best results possible.',
      buttonText: 'Find out more',
    },

    {
      imageSrc:
        'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/274204695_987347705521779_1700836289013212314_n-e1650894123497.jpg',
      subtitle: 'Yorkshire Gladiators',
      title: "Pontefract's number 1 boxing gym",
      description:
        'Meet the team behind our boxing gym, Anthony Holmes - World Champion and Ryan Robinson - Ultimate Shred Business owner .',
      buttonText: 'Book a slot',
    },
    {
      imageSrc: 'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/personal-trainer.jpeg',
      subtitle: 'Our Team',
      title: 'Come and see our Personal Trainers',
      description:
        "We have an industry leading team of personal trainers who are dedicated to helping you achieve your goals. You'll also have the option to trial a 30 minute Personal Training session with our trainers and boxing coaches completely free of charge.",
      buttonText: "Meet the PT's",
    },
    {
      imageSrc:
        'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/196468322_386867096006269_890485535786711285_n.jpg',
      subtitle: 'Fitness Classes',
      title: 'Yorkshire Gladiators & Ultimate Shred Classes',
      description:
        'You’ll have the opportunity to try our classes for both the fitness and boxing.',
      buttonText: 'Book a slot',
    },
    {
      imageSrc:
        'https://images.pexels.com/photos/3998001/pexels-photo-3998001.jpeg?cs=srgb&dl=pexels-cottonbro-3998001.jpg&fm=jpg',
      subtitle: 'Our Team',
      title: 'Sport Massage Therapy ',
      description:
        'Onsite Sports massage facility that is designed to assist in correcting problems and imbalances in soft tissue that are caused from repetitive and strenuous physical activity.',
      buttonText: 'Book a slot',
    },
    {
        imageSrc:
          'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/241448059_541591456942843_4970413379763530000_n.jpg',
        subtitle: 'Tattoo Studio',
        title: 'Ultimate Ink Tattoo',
        description:
          "Danny long bottom tattoo artist will be making an appearance at our open day, he's an exceptional artist and is looking forward to show casing some of his work.",
        buttonText: 'Meet Danny',
      },
    // {
    //   imageSrc: 'https://www.ultimateshred.co.uk/wp-content/uploads/2022/04/HD-Supplements.png',
    //   subtitle: 'Nutritional Supplements',
    //   title: 'Hi-Definition Supplements ',
    //   description:
    //     'Hi-Definition Supplements will be coming to the open day and providing customers with Protein mocktails.',
    //   buttonText: 'Taste a supplement',
    // },
  ]

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Wondering what it's like at Ultimate Shred? </HeadingTitle>
          <HeadingDescription>
            Here's your chance to get up close and personal with our trainers.. Meet Ryan, Jay,
            Anthony and the rest of the team, have a go at Classes and see what it's like to be part
            of a real community...
          </HeadingDescription>
        </HeadingInfoContainer>
        <Video>
          <ResponsiveVideoEmbed
            ratio="100%"
            url="https://player.vimeo.com/video/703342830?h=11089a8a15"
          />
        </Video>
        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <AnchorLink href="#book">
                  <Link>{card.buttonText}</Link>
                </AnchorLink>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
      <HeadingInfoContainer>
        <HeadingTitle>Ultimate App</HeadingTitle>
        <HeadingDescription>providing everything you need at your finger tip's.</HeadingDescription>
      </HeadingInfoContainer>
      <Video>
        <ResponsiveVideoEmbed url="https://player.vimeo.com/video/497407633?h=6efacdb39f" />
      </Video>
    </Container>
  )
}
