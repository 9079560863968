import 'tailwindcss/dist/base.css'
import 'styles/globalStyles.css'
import React from 'react'

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import { BrowserRouter as Router, Route } from 'react-router-dom'
import OpenDayLandingPage from 'pages/OpenDayLandingPage'
import CookieConsent from 'react-cookie-consent'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReactPixel from 'react-facebook-pixel'

const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
ReactPixel.init('678504990176709', advancedMatching, options)
ReactPixel.revokeConsent()

export default function App() {
  const onAccept = () => {
    ReactPixel.grantConsent()
    ReactPixel.pageView()
   }

  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  return (
    <Router>
      <Route path="/">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <OpenDayLandingPage />
      </Route>
      <CookieConsent
        //debug={true}
        location="bottom"
        buttonText="I understand"
        cookieName="UltimateShredCookieConsent"
        style={{ background: '#000' }}
        buttonStyle={{ color: '#fff', fontSize: '13px', backgroundColor: '#59A200' }}
        expires={365}
        onAccept={onAccept}
        acceptOnScroll={true}
        acceptOnScrollPercentage={25}
      >
        We use cookies to personalise content and ads and to analyse our traffic. You consent to our
        cookies if you continue to use our website. The data submitted via the booking form will be
        processed using MailerLite, more information on there policies can be found{' '}
        <a href="https://www.mailerlite.com/legal" style={{ color: '#59A200' }}>
          here.
        </a>
      </CookieConsent>
    </Router>
  )
}
